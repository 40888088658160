<template>
  <div class="payment-bid-main ">
    <div class="payment-wrapper">
      <div class="abr-body">
        <div class="abr-title">
          <h4>Edit payment details</h4>
          <!-- <p>
            Please fill up the payment details
            <img class="align-top" :src="require('@assets/images/black-lock.svg')" alt="" />
          </p> -->
        </div>
        <div class="row">
          <div class="edit-payment-detail col-lg-6">
            <div class="card-detail w-100">
              <div class="card-form">
                <div class="sc-title">
                  <h5>Credit card details</h5>
                  <div class="card-list">
                    <a href="javascript:;"
                      ><img :src="require('@assets/images/visa.png')" alt=""
                    /></a>
                    <a href="javascript:;"
                      ><img :src="require('@assets/images/master-card.png')" alt=""
                    /></a>
                    <a href="javascript:;"
                      ><img :src="require('@assets/images/american-express.png')" alt=""
                    /></a>
                  </div>
                </div>
                <div class="card-row">
                  <div class="form-group">
                    <div class="form-control" id="card-number"></div>
                  </div>
                </div>
                <div class="card-row crfold">
                  <div class="form-group">
                    <div class="form-control" id="card-expiry"></div>
                  </div>
                  <div class="form-group">
                    <div class="form-control" id="card-cvc"></div>
                  </div>
                </div>
                <div id="card-error"></div>
              </div>
            </div>
            <br />
            <button id="custom-button" @click="formSubmit" type="submit" class="btn btn-primary">
              <span
                v-if="isMethodAdd"
                class="spinner-border spinner-border-lg"
                role="status"
                aria-hidden="true"
              ></span>
              <span  v-if="!isMethodAdd">
                <img class="me-1" :src="require('@assets/images/lock-white.svg')" alt="" />
                Submit
              </span>
            </button>
          </div>
          <div class="booking-fold col-lg-6">
            <div class="booking-table table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Card</th>
                    <th>Expiry date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody v-if="rows.length > 0 && !isfetchingcard">
                  <tr v-for="(paymentDetails, index) in rows" v-bind:key="index">
                    <td>
                      **** **** **** {{ paymentDetails.last_four }}
                      <p class="badge bg-primary ms-2 mb-0" v-if="index == '0'">Default</p>
                    </td>
                    <td>{{ paymentDetails.exp_month }} / {{ paymentDetails.exp_year }}</td>
                    <td style="cursor: pointer" v-if="index != '0'">
                      <div class="action">
                        <a @click="deleteMethod(paymentDetails.id)"
                          > <img :src="require('@assets/images/trash.svg')" alt="" /></a>
                        <a class="ms-2" @click="defaultMethod(paymentDetails.id)"
                          data-bs-toggle="tooltip" data-bs-html="true"
                          title="Set as a default payment method">
                          <img :src="require('@assets/images/check-mark.png')" alt="" />
                        </a>
                      </div>
                    </td>
                    <td v-else>-</td>
                  </tr>
                </tbody>
                <tbody v-if="isfetchingcard">
                  <tr>
                    <td colspan="100%" style="text-align: center">
                      <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="rows.length == 0 && !isfetchingcard">
                  <tr>
                    <td colspan="100%" style="text-align: center">
                      <b>Payment details not found</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';

export default {
  name: 'PaymentDetails',
  data() {
    return {
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      stripe: null,
      intentToken: '',
      isMethodAdd: false,
      isfetchingcard: false,
      rows: [],
    };
  },
  created() {
    this.getPaymentDetails();
  },
  computed: {
    stripeElements() {
      return this.stripe.elements();
    },
  },
  async mounted() {
    this.loadIntent();
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);

    const style = {
      base: {
        color: 'black',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '14px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    };
    this.cardNumber = this.stripeElements.create('cardNumber', { style });
    this.cardNumber.mount('#card-number');
    this.cardExpiry = this.stripeElements.create('cardExpiry', { style });
    this.cardExpiry.mount('#card-expiry');
    this.cardCvc = this.stripeElements.create('cardCvc', { style });
    this.cardCvc.mount('#card-cvc');
    this.$nextTick(() => {
      this.cardNumber.on('change', (event) => {
        const displayError = document.getElementById('card-error');
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = '';
        }
      });
      this.cardExpiry.on('change', (event) => {
        const displayError = document.getElementById('card-error');
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = '';
        }
      });
      this.cardCvc.on('change', (event) => {
        const displayError = document.getElementById('card-error');
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = '';
        }
      });
    });
  },
  beforeDestroy() {
    this.cardNumber.destroy();
    this.cardExpiry.destroy();
    this.cardCvc.destroy();
  },
  methods: {
    loadIntent() {
      this.$http
        .get('/customer/stripe/getSetupIntent')
        .then((response) => {
          this.intentToken = response.data;
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
        });
    },
    savePaymentMethod(method) {
      this.$http
        .post('/customer/stripe/postPaymentMethods', {
          payment_method: method,
        })
        .then(() => {
          this.getPaymentDetails();
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
        })
        .finally(() => {});
    },
    formSubmit() {
      this.isMethodAdd = true;
      this.stripe
        .confirmCardSetup(this.intentToken.client_secret, {
          payment_method: {
            card: this.cardNumber,
            billing_details: {
              name: JSON.parse(localStorage.getItem('userData')).full_name ?? '',
            },
          },
        })
        .then((result) => {
          if (result.error) {
            document.getElementById('card-error').innerHTML = result.error.message;
          } else {
            this.savePaymentMethod(result.setupIntent.payment_method);
            this.cardNumber.clear();
            this.cardExpiry.clear();
            this.cardCvc.clear();
            this.$toast.open('Payment method add successfully', 'success');
          }
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
        })
        .finally(() => {
          this.isMethodAdd = false;
          this.loadIntent();
        });
    },
    getPaymentDetails() {
      this.isfetchingcard = true;
      this.$http
        .get('/customer/stripe/cards/list')
        .then((res) => {
          const sortedObjs = res.data.data.sort(
            (a, b) => b.is_default_payment_method - a.is_default_payment_method,
          );
          this.rows = sortedObjs;
        })
        .catch((error) => {
          this.rows = [];
          this.$toast.error(error.response.data.message);
        })
        .finally(() => {
          this.isfetchingcard = false;
        });
    },
    deleteMethod(paymentMethodId) {
      this.isfetchingcard = true;
      this.$http
        .post('/customer/stripe/removePaymentMethod', {
          payment_method_id: paymentMethodId,
        })
        .then((res) => {
          this.rows = res.data.data;
          this.$toast.open('Payment method deleted successfully', 'success');
        })
        .catch((error) => {
          this.rows = [];
          setTimeout(this.$toast.error(error.response.data.message), 8000);
        })
        .finally(() => {
          this.isfetchingcard = false;
          this.getPaymentDetails();
        });
    },
    defaultMethod(paymentMethodId) {
      this.isfetchingcard = true;
      this.$http
        .post('/customer/stripe/setDefaultPaymentMethod', {
          payment_method_id: paymentMethodId,
        })
        .then((res) => {
          this.rows = res.data.data;
          setTimeout(this.$toast.open('Default payment method set successfully', 'success'), 8000);
        })
        .catch((error) => {
          this.rows = [];
          this.$toast.error(error.response.data.message);
        })
        .finally(() => {
          this.isfetchingcard = false;
          this.getPaymentDetails();
        });
    },
  },
};
</script>

<style scoped>
#card-error {
  color: red;
}
</style>
